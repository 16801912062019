//
//
//

export default {
  data() {
    return {
      width: "",
      height: "",
      mshopUrl: ""
    };
  },
  created() {
    let wiHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    this.height = wiHeight + "px";
    let mshopUrl = this.$route.query.mshopUrl;
    this.mshopUrl = mshopUrl;
  }
};
